import api from '@/api'
import url from '@/util/requestUrl'
/**
 *
 * @param {query} query  参数
 */
export function getAncientSayingList(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.getIdiom,
        method: 'get',
        params: query
    })
}

/**
 *
 * @param {query} query  参数
 */
export function getAncientSayingMp3List(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.getAncientSayingMp3List,
        method: 'get',
        params: query
    })
}

/**
 *getAncientSayingTop
 * @param {query} query  参数
 */
export function getAncientSayingTop(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.getAncientSayingTop,
        method: 'get',
        params: query
    })
}
