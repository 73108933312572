import pinia from '@/store'
import useSettingsStore from '@/store/modules/settings'

// 固定路由（默认路由）
let constantRoutes = [
    //
    {
        path: '/',
        component: () => import('@/layout/index.vue'),
        meta: {
            title: () => {
                return useSettingsStore().dashboard.title
            },
            breadcrumb: false
        },
        children: [{
            path: '',
            name: 'index',
            component: () => import('@/views/index.vue'),
            meta: {
                title: () => {
                    return useSettingsStore().dashboard.title
                },
                breadcrumb: false
            }
        }]
    },
    // {
    //     path: '/dashboard',
    //     component: () => import('@/layout/index.vue'),
    //     meta: {
    //         title: () => {
    //             return useSettingsStore().dashboard.title
    //         },
    //         breadcrumb: false
    //     },
    //     children: [{
    //         path: '',
    //         name: 'dashboard',
    //         component: () => import('@/views/index.vue'),
    //         meta: {
    //             title: () => {
    //                 return useSettingsStore().dashboard.title
    //             },
    //             breadcrumb: false
    //         }
    //     }]
    // },
    {
        path: '/top',
        component: () => import('@/layout/index.vue'),
        meta: {
            title: () => {
                return useSettingsStore().dashboard.title
            },
            breadcrumb: false
        },
        children: [{
            path: '',
            name: 'top',
            component: () => import('@/views/index.vue'),
            meta: {
                title: () => {
                    return '推荐' + useSettingsStore().dashboard.title
                },
                breadcrumb: false
            }
        }]
    },
    {
        path: '/gushi',
        component: () => import('@/layout/index.vue'),
        meta: {
            title: () => {
                return useSettingsStore().dashboard.title
            },
            breadcrumb: false
        },
        children: [{
            path: '',
            name: 'gushi',
            component: () => import('@/views/gushi.vue'),
            meta: {
                title: () => {
                    return '古诗' + useSettingsStore().dashboard.title
                },
                breadcrumb: false
            }
        }]
    },
    // {
    //     path: '/gushu',
    //     component: () => import('@/layout/index.vue'),
    //     meta: {
    //         title: () => {
    //             return useSettingsStore().dashboard.title
    //         },
    //         breadcrumb: false
    //     },
    //     children: [{
    //         path: '',
    //         name: 'guwen',
    //         component: () => import('@/views/gushu.vue'),
    //         meta: {
    //             title: () => {
    //                 return '古书' + useSettingsStore().dashboard.title
    //             },
    //             breadcrumb: false
    //         }
    //     }]
    // },
    {
        path: '/gushuDetail',
        component: () => import('@/layout/index.vue'),
        meta: {
            title: () => {
                return useSettingsStore().dashboard.title
            },
            breadcrumb: false
        },
        children: [{
            path: '',
            name: 'gushuDetail',
            component: () => import('@/views/gushuDetail.vue'),
            meta: {
                title: () => {
                    return '古书' + useSettingsStore().dashboard.title
                },
                breadcrumb: false
            }
        }]
    },
    {
        path: '/gushiDetail',
        component: () => import('@/layout/index.vue'),
        meta: {
            title: () => {
                return useSettingsStore().dashboard.title
            },
            breadcrumb: false
        },
        children: [{
            path: '',
            name: 'gushiDetail',
            component: () => import('@/views/gushiDetail.vue'),
            meta: {
                title: () => {
                    return '古诗' + useSettingsStore().dashboard.title
                },
                breadcrumb: false
            }
        }]
    },
    // {
    //     path: '/mingju',
    //     component: () => import('@/layout/index.vue'),
    //     meta: {
    //         title: () => {
    //             return useSettingsStore().dashboard.title
    //         },
    //         breadcrumb: false
    //     },
    //     children: [{
    //         path: '',
    //         name: 'mingju',
    //         component: () => import('@/views/mingju.vue'),
    //         meta: {
    //             title: () => {
    //                 return '古文' + useSettingsStore().dashboard.title
    //             },
    //             breadcrumb: false
    //         }
    //     }]
    // },
    {
        path: '/chengyu',
        component: () => import('@/layout/index.vue'),
        meta: {
            title: () => {
                return useSettingsStore().dashboard.title
            },
            breadcrumb: false
        },
        children: [{
            path: '',
            name: 'chengyu',
            component: () => import('@/views/chengyu.vue'),
            meta: {
                title: () => {
                    return '成语' + useSettingsStore().dashboard.title
                },
                breadcrumb: false
            }
        }]
    },
    {
        path: '/zitie',
        component: () => import('@/layout/index.vue'),
        meta: {
            title: () => {
                return useSettingsStore().dashboard.title
            },
            breadcrumb: false
        },
        children: [{
            path: '',
            name: 'zitie',
            component: () => import('@/views/zitie.vue'),
            meta: {
                title: () => {
                    return '字帖' + useSettingsStore().dashboard.title
                },
                breadcrumb: false
            }
        }]
    },
    {
        path: '/wenyanwen',
        component: () => import('@/layout/index.vue'),
        meta: {
            title: () => {
                return useSettingsStore().dashboard.title
            },
            breadcrumb: false
        },
        children: [{
            path: '',
            name: 'wenyanwen',
            component: () => import('@/views/wenyanwen.vue'),
            meta: {
                title: () => {
                    return '文言文-古词' + useSettingsStore().dashboard.title
                },
                breadcrumb: false
            }
        }]
    },
    // 手机端首页
    {
        path: '/mobileindex',
        component: () => import('@/layout/index.vue'),
        meta: {
            title: () => {
                return useSettingsStore().dashboard.title
            },
            breadcrumb: false
        },
        children: [{
            path: '',
            name: 'mobileindex',
            component: () => import('@/views/mobileindex.vue'),
            meta: {
                title: () => {
                    return '' + useSettingsStore().dashboard.title
                },
                breadcrumb: false
            }
        }]
    },
    // 手机端详情页
    {
        path: '/mobiledetail',
        component: () => import('@/layout/index.vue'),
        meta: {
            title: () => {
                return useSettingsStore().dashboard.title
            },
            breadcrumb: false
        },
        children: [{
            path: '',
            name: 'mobiledetail',
            component: () => import('@/views/mobiledetail.vue'),
            meta: {
                title: () => {
                    return '' + useSettingsStore().dashboard.title
                },
                breadcrumb: false
            }
        }]
    },

    // 手机端详情页
    {
        path: '/mobilezitie',
        component: () => import('@/layout/index.vue'),
        meta: {
            title: () => {
                return useSettingsStore().dashboard.title
            },
            breadcrumb: false
        },
        children: [{
            path: '',
            name: 'mobilezitie',
            component: () => import('@/views/mobilezitie.vue'),
            meta: {
                title: () => {
                    return '' + useSettingsStore().dashboard.title
                },
                breadcrumb: false
            }
        }]
    },

    // 手机端详情页
    {
        path: '/mobilezitieresult',
        component: () => import('@/layout/index.vue'),
        meta: {
            title: () => {
                return useSettingsStore().dashboard.title
            },
            breadcrumb: false
        },
        children: [{
            path: '',
            name: 'mobilezitieresult',
            component: () => import('@/views/mobilezitieresult.vue'),
            meta: {
                title: () => {
                    return '' + useSettingsStore().dashboard.title
                },
                breadcrumb: false
            }
        }]
    },

    {
        path: '/userProtocal',
        component: () => import('@/layout/index.vue'),
        meta: {
            title: () => {
                return useSettingsStore().dashboard.title
            },
            breadcrumb: false
        },
        children: [{
            path: '',
            name: 'userProtocal',
            component: () => import('@/views/protocal-v20230905.vue'),
            meta: {
                title: () => {
                    return '用户协议'
                },
                breadcrumb: false
            }
        }]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login.vue'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/:all(.*)*',
        name: 'notFound',
        component: () => import('@/views/[...all].vue'),
        meta: {
            title: '找不到页面'
        }
    }
]

// 系统路由
let systemRoutes = [

    {
        path: '/m',
        component: () => import('@/views/m/index.vue'),
        redirect: '/m/index',
        meta: {
            title: '个人中心',
            breadcrumb: false
        },
        children: [
            // 首页
            {
                path: 'index',
                name: 'mobileIndex2',
                component: () => import('@/views/m/index.vue'),
                meta: {
                    title: '首页',
                    cache: 'mIndexCache'
                }
            },
            // 详情
            {
                path: 'detail',
                name: 'mobileDetail2',
                component: () => import('@/views/m/detail1.vue'),
                meta: {
                    title: '详情',
                    cache: 'mIndexCache'
                }
            }
        ]
    },

    {
        path: '/setting',
        name: 'indexSetting',
        component: () => import('@/layout/index.vue'),
        meta: {
            title: '个人设置',
            i18n: 'route.personal.setting',
            cache: 'personalEditPassword'
        },
        children: [{
            path: '',
            name: 'indexSettings',
            component: () => import('@/views/personal/setting.vue'),
            meta: {
                title: '个人设置',
                breadcrumb: false
            }
        }]
    },
    {
        path: '/personal',
        component: () => import('@/layout/index.vue'),
        redirect: '/personal/setting',
        meta: {
            title: '个人中心',
            breadcrumb: false
        },
        children: [
            //
            {
                path: 'setting',
                name: 'personalSetting',
                component: () => import('@/views/personal/setting.vue'),
                meta: {
                    title: '个人设置',
                    i18n: 'route.personal.setting',
                    cache: 'personalEditPassword'
                }
            }
        ]
    },
    {
        path: '/reload',
        component: () => import('@/layout/index.vue'),
        meta: {
            title: '重新加载',
            breadcrumb: false
        },
        children: [{
            path: '',
            name: 'reload',
            component: () => import('@/views/reload.vue'),
            meta: {
                title: '重新加载',
                breadcrumb: false
            }
        }]
    }
]

// 动态路由（异步路由、导航栏路由）
let asyncRoutes = [{
    meta: {
        title: '演示',
        icon: 'sidebar-default'
    },
    children: [
        // MultilevelMenuExample,
        // BreadcrumbExample
    ]
}]

import {
    setupLayouts
} from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'

// if (useSettingsStore(pinia).app.routeBaseOn === 'filesystem') {
//     constantRoutes = generatedRoutes.filter(item => {
//         return item.meta ? .enabled !== false && item.meta ? .constant === true
//     })
//     asyncRoutes = setupLayouts(generatedRoutes.filter(item => {
//         return item.meta ? .enabled !== false && item.meta ? .constant !== true && item.meta ? .layout !== false
//     }))
// }

export {
    constantRoutes,
    systemRoutes,
    asyncRoutes
}
