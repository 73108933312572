import api from '@/api'
import url from '@/util/requestUrl'
/**
 * 获取首页内容
 * @param {query} query  参数
 */
export function getDisplayContent(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.getDisplayContent,
        method: 'get',
        params: query
    })
}
/**
 * 获取首页内容
 * @param {query} query  参数
 */
export function getIndexDisplayContentList(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.getIndexDisplayContentList,
        method: 'get',
        params: query
    })
}
/**
 * 获取首页内容
 * @param {query} query  参数
 */
export function getAllTags(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.getAllTags,
        method: 'get',
        params: query
    })
}
