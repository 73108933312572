import api from '@/api'
import url from '@/util/requestUrl'
/**
 * getAncientPoetryList
 * @param {query} query  参数
 */
export function getAncientPoetryList(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.getAncientPoetryList,
        method: 'get',
        params: query
    })
}

/**
 * getIndexAncientPoetryList
 * @param {query} query  参数
 */
export function getIndexAncientPoetryList(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.getIndexAncientPoetryListUrl,
        method: 'get',
        params: query
    })
}

/**
 * getAncientPoetryDetail
 * @param {query} query  参数
 */
export function getAncientPoetryDetail(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.getAncientPoetryDetail,
        method: 'get',
        params: query
    })
}

/**
 * getAncientGushiMp3List
 * @param {query} query  参数
 */
export function getAncientGushiMp3List(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.getAncientGushiMp3List,
        method: 'get',
        params: query
    })
}

/**
 * getPoetryTodayList
 * @param {query} query  参数
 */
export function getPoetryTodayList(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.getPoetryTodayList,
        method: 'get',
        params: query
    })
}

/**
 * getPoetryTopsList
 * @param {query} query  参数
 */
export function getPoetryTopsList(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.getPoetryTopsList,
        method: 'get',
        params: query
    })
}
