import * as CryptoJS from 'crypto-js'
// APlayer歌曲信息
export class Audio {
    // 音频艺术家
    // artist: String;
    // 音频名称
    // name: String;
    // 音频链接
    // url: String;
    // 音频封面
    // cover: String;
    // 歌词
    // lrc: String;

    constructor(artist, name, url, cover, lrc) {
        this.artist = artist
        this.name = name
        this.url = url
        this.cover = cover
        this.lrc = lrc
    }
}
export const commonText = {
    formatText: [
        // format
        {
            id: 1,
            name: 'aabb',
            plain: '#392b20'
        }, {
            id: 2,
            name: 'aabc',
            plain: '#392b20'
        }, {
            id: 4,
            name: 'abca',
            plain: '#392b20'
        }, {
            id: 5,
            name: 'abca',
            plain: '#392b20'
        }, {
            id: 5,
            name: 'abac',
            plain: '#392b20'
        }, {
            id: 5,
            name: 'abcb',
            plain: '#392b20'
        }, {
            id: 5,
            name: 'abcd',
            plain: '#392b20'
        }
    ],
    pinyinText: [
        // text
        {
            id: 'a',
            name: 'A',
            plain: '#392b20'
        },
        {
            id: 'b',
            name: 'B',
            plain: '#392b20'
        },
        {
            id: 'c',
            name: 'C',
            plain: '#392b20'
        },
        {
            id: 'd',
            name: 'D',
            plain: '#392b20'
        },
        {
            id: 'e',
            name: 'E',
            plain: '#392b20'
        },
        {
            id: 'f',
            name: 'F',
            plain: '#392b20'
        },
        {
            id: 'g',
            name: 'G',
            plain: '#392b20'
        },
        {
            id: 'h',
            name: 'H',
            plain: '#392b20'
        },
        {
            id: 'j',
            name: 'J',
            plain: '#392b20'
        },
        {
            id: 'k',
            name: 'K',
            plain: '#392b20'
        },
        {
            id: 'l',
            name: 'L',
            plain: '#392b20'
        },
        {
            id: 'm',
            name: 'M',
            plain: '#392b20'
        },
        {
            id: 'n',
            name: 'N',
            plain: '#392b20'
        },
        {
            id: 'o',
            name: 'O',
            plain: '#392b20'
        },
        {
            id: 'p',
            name: 'P',
            plain: '#392b20'
        },
        {
            id: 'q',
            name: 'Q',
            plain: '#392b20'
        },
        {
            id: 'r',
            name: 'R',
            plain: '#392b20'
        },
        {
            id: 's',
            name: 'S',
            plain: '#392b20'
        },
        {
            id: 't',
            name: 'T',
            plain: '#392b20'
        },
        {
            id: 'w',
            name: 'W',
            plain: '#392b20'
        },
        {
            id: 'x',
            name: 'X',
            plain: '#392b20'
        },
        {
            id: 'y',
            name: 'Y',
            plain: '#392b20'
        },
        {
            id: 'z',
            name: 'Z',
            plain: '#392b20'
        }
    ],
    wordLengthText: [
        // 其他text
        {
            id: 3,
            name: '三字',
            plain: '#392b20'
        },
        {
            id: 4,
            name: '四字',
            plain: '#392b20'
        },
        {
            id: 5,
            name: '五字',
            plain: '#392b20'
        },
        {
            id: 6,
            name: '六字',
            plain: '#392b20'
        },
        {
            id: 7,
            name: '七字',
            plain: '#392b20'
        },
        {
            id: 8,
            name: '八字',
            plain: '#392b20'
        },
        {
            id: 9,
            name: '九字',
            plain: '#392b20'
        },
        {
            id: 10,
            name: '十字',
            plain: '#392b20'
        },
        {
            id: 11,
            name: '十字以上',
            plain: '#392b20'
        }
    ]
}

export const props = {
    // 开启吸底模式
    fixed: {
        type: Boolean,
        default: true
    },
    // 开启迷你模式
    mini: {
        type: Boolean,
        default: true
    },
    // 音频自动播放
    autoplay: {
        type: Boolean,
        default: true
    },
    // 主题色
    theme: {
        type: String,
        default: 'rgba(255,255,255,0.2)'
    },
    // 音频循环播放
    loop: {
        type: String,
        default: 'none' // 'all' | 'one' | 'none'
    },
    // 音频循环顺序
    order: {
        type: String,
        default: 'list' // 'list' | 'random'
    },
    // 预加载
    preload: {
        type: String,
        default: 'auto' // 'auto' | 'metadata' | 'none'
    },
    // 默认音量
    volume: {
        type: Number,
        default: 0.7,
        validator: value => {
            return value >= 0 && value <= 1
        }
    },
    // 歌曲服务器(netease-网易云, tencent-qq音乐, kugou-酷狗, xiami-小米音乐, baidu-百度音乐)
    songServer: {
        type: String,
        default: 'tencent' // 'netease' | 'tencent' | 'kugou' | 'xiami' | 'baidu'
    },
    // 播放类型(song-歌曲, playlist-播放列表, album-专辑, search-搜索, artist-艺术家)
    songType: {
        type: String,
        default: 'playlist'
    },
    // 歌的id
    songId: {
        type: String,
        default: '19723756'
    },
    // 互斥，阻止多个播放器同时播放，当前播放器播放时暂停其他播放器
    mutex: {
        type: Boolean,
        default: true
    },
    // 传递歌词方式
    lrcType: {
        type: Number,
        default: 3
    },
    // 列表是否默认折叠
    listFolded: {
        type: Boolean,
        default: true
    },
    // 列表最大高度
    listMaxHeight: {
        type: String,
        default: '0px'
    },
    // 存储播放器设置的 localStorage key
    storageName: {
        type: String,
        default: 'aplayer-setting'
    }
}
export function setGlobalColor() {
    document.body.style.setProperty('--el-color-primary', '#f56c6c')
    document.body.style.setProperty('--el-color-primary-light-3', '#fab6b6')
    document.body.style.setProperty('--el-color-primary-light-5', '#fab6b6')
    document.body.style.setProperty('--el-color-primary-light-7', '#fcd3d3')
    document.body.style.setProperty('--el-color-primary-light-8', '#fde2e2')
    document.body.style.setProperty('--el-color-primary-light-9', '#fef0f0')
}

export const cssData = reactive({
    topCssDataTop: {
        paddingTop: '10px', // 初始值
        marginLeft: '180px', // 初始值
        marginRight: '180px' // 初始值
    },
    topCssDataBottom: {
        marginLeft: '180px', // 初始值
        marginRight: '180px' // 初始值
    },
    topCssDataBottomDetail: {
        marginLeft: '8px', // 初始值
        marginRight: '8px', // 初始值
        marginBottom: '5px'
    }
})

import useSettingsStore from '@/store/modules/settings'

export function setPcAndH5PaddingCssStyle() {
    const settingsStore = useSettingsStore()
    if (settingsStore.mode === 'pad') {
        cssData.topCssDataTop = {
            paddingTop: '10px', // 初始值
            marginLeft: '28px', // 初始值
            marginRight: '28px' // 初始值
        }
        cssData.topCssDataBottom = {
            marginLeft: '28px', // 初始值
            marginRight: '28px' // 初始值
        }
    }
    if (settingsStore.mode === 'mobile') {
        cssData.topCssDataTop = {
            paddingTop: '10px', // 初始值
            marginLeft: '28px', // 初始值
            marginRight: '28px' // 初始值
        }
        cssData.topCssDataBottom = {
            marginLeft: '28px', // 初始值
            marginRight: '28px' // 初始值
        }
    }
}

export function getCurrentMode() {
    const settingsStore = useSettingsStore()
    return settingsStore.mode
}

export function toUrl(targetUrl, param, target) {
    const router = useRouter()
    let route2
    if (param === '') {
        route2 = router.resolve({
            path: targetUrl
        })
    } else {
        route2 = router.resolve({
            path: targetUrl,
            query: {
                key: param
            }
        })
    }
    window.open(route2.href, target)
}

export function pushUrl(targetUrl, param) {
    const router = useRouter()
    if (param) {
        router.push({
            name: targetUrl,
            query: param
        })
    } else {
        router.push({
            name: targetUrl
        })
    }

}

export function alertMsg(msg, type) {
    ElMessage({
        message: msg,
        type: type
    })
}
// 返回
export function checkNullZeroValue(value) {
    return value === null || value === '' || value === undefined
}
// 返回数组为空true
export function checkNullZeroArray(myArray) {
    console.log(!myArray || myArray.length <= 0)
    return !myArray || myArray.length <= 0
}

// //验证是否是中文
export function isDigEnglish(str) {
    // todo 判断是否是中文 、空格换行等字符
    var pattern = new RegExp(/[0-9a-zA-Z]/)
    if (pattern.test(str)) {
        return true
    } else {
        return false
    }
}

// //验证是否是英文
export function isEnglish(str) {
    var pattern2 = new RegExp('[A-Za-z]+')
    if (pattern2.test(str)) {
        return true
    } else {
        return false
    }
}

/**
 *加密处理
 */
export function encryption(params) {
    let {
        data,
        type,
        param,
        key
    } = params
    const result = JSON.parse(JSON.stringify(data))
    // console.log('encryption params=', params)
    if (type === 'Base64') {
        param.forEach(ele => {
            result[ele] = btoa(result[ele])
        })
    } else {
        param.forEach(ele => {
            var data = result[ele]
            key = CryptoJS.enc.Latin1.parse(key)
            var iv = key
            // 加密
            var encrypted = CryptoJS.AES.encrypt(data, key, {
                iv: iv,
                mode: CryptoJS.mode.CFB,
                padding: CryptoJS.pad.NoPadding
            })
            result[ele] = encrypted.toString()
        })
    }
    return result
}
